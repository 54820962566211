
import fetchWrapper from "../../modules/fetchWrapper";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default (Vue) => {
	new Vue({
		el: "#v-global-search-page",
		components: { Autocomplete },
		data(){
			return {
				phrase: "",
				inputToggled: false,
				isModalVisible: false,
				isSearchBarVisible: false,
				isLoading: false,
				items: [],
				debounce: null
			}
		},
		methods: {
			async onSearch(search) {
				this.phrase = search
				if (search.length < 1) return []
	
				const [status, result] = await fetchWrapper({
					url: `/wp-admin/admin-ajax.php?action=global_search&phrase=${search}`,
					method: "GET",
				});

				this.items = result.data
				if (!result.data && result.data.length < 1) return []
				return []
			},
			getResultValue(result) {
				if (!result) return ''
				this.phrase = result && result.title
				return result && result.title
			},
			async submit(e) {
				this.isLoading = true;

				const [status, result] = await fetchWrapper({
					url: `/wp-admin/admin-ajax.php?action=global_search&phrase=${this.phrase}`,
					method: "GET",
				});
				this.isLoading = false;

				if(status >= 300){
				}else{
					this.isModalVisible = true;
					this.items = result.data
				}
			},
			highlight(text) {
				return text.replace(new RegExp(this.phrase, "gi"), match => {
					return '<span class="font-bold">' + match + '</span>';
				});
			},
			debounceSearch(event) {
				clearTimeout(this.debounce)
				this.debounce = setTimeout(() => {
					this.submit(event);
				}, 600)
			},
		},
		mounted(){
			const el = document.querySelector('[data-phrase]')
			this.phrase = el.getAttribute('data-phrase')
			if(this.phrase) this.submit();
		}
	})
	
}