
import fetchWrapper from "../../modules/fetchWrapper";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default (Vue) => {
	new Vue({
		el: "#v-search",
		components: { Autocomplete },
		data(){
			return {
				phrase: "",
				inputToggled: false,
				isModalVisible: false,
				isSearchBarVisible: false,
				isLoading: false,
				items: [],
				debounce: null
			}
		},
		methods: {
			async onSearch(search) {
				this.phrase = search
				if (search.length < 1) return []
	
				const [status, result] = await fetchWrapper({
					url: `/wp-admin/admin-ajax.php?action=global_search&phrase=${search}`,
					method: "GET",
				});

				if (!result.data && result.data.length < 1) return []
				return result.data
			},
			getResultValue(result) {
				if (!result) return ''
				this.phrase = result && result.title
				return result && result.title
			},
			async submit(e) {
				this.isLoading = true;

				const [status, result] = await fetchWrapper({
					url: `/wp-admin/admin-ajax.php?action=global_search&phrase=${this.phrase}`,
					method: "GET",
				});
				this.isLoading = false;

				if(status >= 300){
				}else{
					window.location.href = `/s?phrase=${this.phrase}`
					// this.isModalVisible = true;
					// this.items = result.data
				}
			},
			highlight(text) {
				return text.replace(new RegExp(this.phrase, "gi"), match => {
					return '<span class="font-bold">' + match + '</span>';
				});
			},
			toggleModal(){
				document.body.classList.toggle('overflow-hidden')
				this.isModalVisible = !this.isModalVisible;
				if(!this.isModalVisible){
					this.phrase = "";
					this.toggleInput();
				}
			},
			toggleInput(){
				this.inputToggled = !this.inputToggled;
				this.$nextTick(() => {
					if(!this.$refs.input) return;
					this.$refs.input.focus();
				})
			},
			debounceSearch(event) {
				clearTimeout(this.debounce)
				this.debounce = setTimeout(() => {
					this.submit(event);
				}, 600)
			},
		},
	})
	
}