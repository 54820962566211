import jsonParser from '../modules/jsonParser';
import moment from 'moment';
import plLocale from 'moment/locale/pl'
import enLocale from 'moment/locale/en-gb'
import 'chartjs-adapter-moment';


(async () => {
	const chartsDOM = document.querySelectorAll('.js-chart');
	if (chartsDOM) {
		const zoomPlugin = await import('chartjs-plugin-zoom');
		const { Chart, PieController,TimeScale, LineController, Filler, PointElement, LineElement, ArcElement, LinearScale, BarController, BarElement, CategoryScale, Tooltip, Legend } = await import("chart.js");
		Chart.register([LinearScale, TimeScale, BarController, Filler, LineController, PointElement, LineElement, ArcElement, PieController, BarElement, CategoryScale, Tooltip, Legend]);


		chartsDOM.forEach(element => {
			const data = jsonParser(element.getAttribute('data-data'));
			const type = element.getAttribute('data-type') || 'bar';

			if(type != 'line'){
				new Chart(element, {
					type,
					data: data,
					options: {
						plugins: {
							tooltip:{
								enabled: true,
							},
							legend: {
								onClick: null
							},
							zoom: type === 'line' ? {
								drag: {
									enabled: true
								},
								mode: 'x',
							} : {},
						},
						responsive: true,
						scales: type === 'bar' ? {
							x: {
								stacked: true,
							},
							y: {
								stacked: true
							}
						} : {},
					}
				});
			}else{
				if(element.getAttribute('data-lang') == 'pl'){
					moment.locale('pl', plLocale);
				}else{
					moment.locale('en', enLocale);
				}
				
				Chart.register(zoomPlugin.default)
				const chart = new Chart(element, {
					type,
					data: {
						datasets: [
							{
								data,
								backgroundColor : '#003087',
								pointBackgroundColor: '#003087',
								pointHoverBackgroundColor: '#003087',
								borderColor: '#003087',
								pointBorderColor: '#003087',
								pointHoverBorderColor: '#003087',
								borderWidth: 4,
								fill: true,
								tension: 0.2
							}
						]
					},
					options: {
						fill: false,
						interaction: {
							intersect: false
						},
						radius: 0,
						plugins: {

							legend: {
								display:false,
							},
							zoom: {
								pan: {
									enabled: true,
									mode: 'x',
									modifierKey: 'ctrl',
								},
								zoom: {
									drag: {
										enabled: true
									},
									mode: 'x'
								}
							}
						},
						responsive: true,
						scales: {
							x: {
								type: 'time',
								position: 'bottom',
								ticks: {
									autoSkip: true,
									autoSkipPadding: 50,
									maxRotation: 0
								},
								time: {
									unit: "month",
									displayFormats: {
										'millisecond': 'DD/MM/YYYY',
										'second': 'DD/MM/YYYY',
										'minute': 'h:mm',
										'hour': 'h:mm',
										'day': 'DD MMMM',
										'week': 'DD MMMM',
										'month': 'MMMM YYYY',
										'quarter': 'DD/MM/YYYY',
										'year': 'YYYY',
									},
									tooltipFormat: 'DD MMMM',
									// unit: 'month',
								 }
							},
						},
					}
				});

				const ctx = chart.ctx;
				var gradient = ctx.createLinearGradient(0, 0, 0, 600);
				gradient.addColorStop(0, 'rgb(0,48,135, 0.45)');
				gradient.addColorStop(1, '#00308700');
				chart.data.datasets[0].backgroundColor = gradient;
				chart.data.datasets[0].fillColor = gradient;

				chart.update();

				window.filterTimeChartByDate = (period, item) => {
					let startDate = '';
					if(period == 'day') startDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
					if(period == '14days') startDate = moment().subtract(14, 'days').format('YYYY-MM-DD')
					if(period == 'month') startDate = moment().subtract(1, 'months').format('YYYY-MM-DD')
					if(period == '6months') startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
					if(period == 'year') startDate = moment().subtract(1, 'years').format('YYYY-MM-DD')

					let dataClone = [...data]
					dataClone = dataClone.filter(x => {
						if(moment(startDate).isBefore(moment(x.x, 'YYYY-MM-DD'))){
							return true;
						}
						return false;
					})

					if(period != 'day'){
						let previousValue = 0
						let todaysValue = 0
						let actualDate = null
						dataClone = dataClone.filter((x, index) => {
							const nextDate = dataClone[index + 1] ? moment(dataClone[index + 1].x).format('YYYY-MM-DD') : null;
							
							if(nextDate){
								const thisDate = moment(dataClone[index].x).format('YYYY-MM-DD');
								if(thisDate == nextDate) return false;
								else previousValue = dataClone[index].y;
							}else{
								todaysValue = dataClone[index].y;
								actualDate = dataClone[index].x;
							}
							return true;
						})

						const el = document.querySelector('.js-calculate-change')
						if(el && el.innerHTML == "") el.innerHTML = `${(todaysValue - previousValue).toFixed(2)} (${((todaysValue - previousValue) / previousValue * 100).toFixed(2)}%)`;
						const el2 = document.querySelector('.js-actual-date')
						if(el2 && el2.innerHTML == "" && actualDate) el2.innerHTML = moment(actualDate).format('YYYY-MM-DD HH:mm');
					}

					chart.config.data.datasets[0].data = dataClone;

					if(period == 'day'){
						chart.options.scales.x.time.unit = 'hour';
					}else if(period == '14days'){
						chart.options.scales.x.time.unit = 'day';
					}else if(period == 'month'){
						chart.options.scales.x.time.unit = 'week';
					}else{
						chart.options.scales.x.time.unit = 'month';
					}

					if(item) document.querySelectorAll('.js-time-filter').forEach(x => x.classList.remove('is-active'))
					if(item) item.classList.add('is-active')
					chart.resetZoom();
					chart.update();
				}

				filterTimeChartByDate('year')

				window.setXAxis = (unit) => {
					chart.options.scales.x.time.unit = unit;
					chart.update();
				};
			}
		});
	}
})();

// "labels": ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],

// "data": [
// 	{ "x": "19/07/2021", "y": 28.08 },
// 	{ "x": "20/07/2021", "y": 27.52 },
// 	{ "x": "21/07/2021", "y": 27.00 },
// 	{ "x": "22/07/2021", "y": 26.80 },
// 	{ "x": "23/07/2021", "y": 26.90 },
// 	{ "x": "26/07/2021", "y": 27.30 }
// ]