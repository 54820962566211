const preReveal = (className) => {
	if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) return;

	const elements = document.querySelectorAll(className)
	elements.forEach(el => {
		el.style.opacity = "0"
	})
}

const reveal = (className, options) => {
	/** Fallback to not using reveal in browser that doesn't support IntersectionObserver */
	if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window) || !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) return;

	let reset = options.reset ? true : false;
	const elements = document.querySelectorAll(className)
	elements.forEach(el => {
		el.style.opacity = "0"
	})
	const observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => {
			if(entry.target.dataset.reset == "0") reset = false;
			let options = {
				easing: entry.target.dataset.easing || "ease-in-out",
				duration: entry.target.dataset.duration || "700",
				delay: entry.target.dataset.delay || "0",
				animation: entry.target.dataset.animation || "reveal-bottom"
			};


			if(entry.intersectionRatio > 0 || entry.isIntersecting){
				entry.target.style.animation = `${options.animation} ${options.duration}ms ${options.delay}ms forwards ease-out`
			}else{
				if(entry.target.dataset.reset != "0") entry.target.style.animation = 'none'
			}
		})
	})
	elements.forEach(el => observer.observe(el))
}

export { preReveal, reveal };
