window.state = {
	isFullyLoaded: false
};

// window.proxy = new Proxy(state, {
// 	set: function (target, key, value) {
// 		target[key] = value;
// 		callback(key, value);
// 		return true;
// 	}
// })

// window.onStateChange = (callback) => {
// 	return proxy;
// }

window.onStateChange = (callback) => {
	const proxy = new Proxy(state, {
		set: function (target, key, value) {
			target[key] = value;
			callback(key, value);
			return true;
		}
	})
	return proxy;
}

// window.state = new Proxy(stateObj, {
//   set: function (target, key, value) {
// 		console.log(`${key} set to ${value}`);
// 		target[key] = value;
// 		return true;
//   }
// });
