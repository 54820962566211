import anime from 'animejs';

const curtainOpenerTimeline = anime.timeline({
	autoplay: false,
	complete: function(){
		fade();
	}
});


curtainOpenerTimeline
	.add({
		targets: '.js-page-loader-logo',
		duration: 1000,
		scale: "0.3",
		opacity: 0,
		easing: 'easeOutSine'
	})
	.add({
		targets: '.js-page-loader-curtain',
		duration: 1200,
		translateY: "-100%",
		easing: 'easeInCubic'
	}, "-=1000");

const seconda = anime.timeline({autoplay:false, delay: 1200});
seconda.add({
	targets: '.js-header-desktop',
	duration: 1500,
	opacity: [0, 1],
	translateY: ["-100%", 0],
	easing: 'easeOutExpo'
})
.add({
	targets: '.js-frontpage-social-media',
	duration: 2000,
	opacity: [0, 1],
	translateY: ["100%", 0],
	easing: 'easeOutExpo'
}, "-=3000");

window.onunload = function(){};


const proxy = onStateChange((key, value) => {
	if(key == 'isFullyLoaded' && value == true){
		setTimeout(() => {
			console.log('curtainOpener')
			curtainOpenerTimeline.play();
			seconda.play();
		}, 500)
	}
})

proxy.isFullyLoaded = true;


/**
 * Animate curtain before leaving page
 */
window.beforeLeave = function (event, target) {
	if(target.getAttribute('target') && target.getAttribute('target') == "_blank") return;

	event.preventDefault();
	curtainOpenerTimeline.reverse();
	curtainOpenerTimeline.play();

	setTimeout(() => {
		window.location.href = target.getAttribute("href");
	}, 300)
}

const menuItems = document.querySelectorAll('.js-header-desktop a');
menuItems.forEach(el => {
	if(!el.getAttribute('href').includes('#')){
		el.addEventListener('click', (event) => {
			beforeLeave(event, el);
		})
	}
});