export default class dialogueForm {
	constructor() {
		this.stepsContainer = document.querySelector('.js-df-steps');
		if (! this.stepsContainer) return;
	
		this.initObserver(this.stepsContainer.parentElement);
	
		this.groups = document.querySelectorAll('.js-df-group');
		this.groups.forEach((group, index) => {
			let name = group.dataset.name;
			let indicator = this.createIndicator(this.stepsContainer, name, index, this.groups.length);
	
			let inputs = group.querySelectorAll('input, textarea');
			inputs.forEach(input => {
				if (input.type == 'radio') {
					input.checked = false;
					input.removeAttribute('checked');
				}
	
				input.addEventListener('input', event => {
					if (this.validateInputs(inputs))	indicator.classList.add('completed');
					else															indicator.classList.remove('completed');
				});
			});
		});
	}
	createIndicator(container, name, index, total) {
		let box = document.createElement('div');
		box.classList = "[&.completed]:border-quinary [&.completed]:text-white [&.completed]:bg-quinary flex-1 flex flex-col justify-center items-center gap-6 border-1 border-solid border-secondary text-center text-black bg-white";

		let number = document.createElement('span');
		number.classList = "font-extrabold text-3xl sm:text-4xl 3xl:text-6xl";
		number.innerText = `${index + 1}/${total}`;

		let text = document.createElement('span');
		text.classList = "text-lg font-light";
		text.innerText = name;

		box.appendChild(number);
		box.appendChild(text);
		container.appendChild(box);

		return box;
	}
	initObserver(el) {
    let scrollObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
				// console.log(entry.target);
				// console.log(entry.target.firstElementChild);
				if (entry.boundingClientRect.top < 0 && !entry.isIntersecting) {
					entry.target.firstElementChild.classList.remove('static');
          entry.target.firstElementChild.classList.add('fixed');
				} else {
					entry.target.firstElementChild.classList.remove('fixed');
					entry.target.firstElementChild.classList.add('static');
				}
      });
    }, {
      threshold: 1,
    });
  
    scrollObserver.observe(el);
  }
	validateInputs(inputs) {
		let valid = true;
		let validRadios = [];

		inputs.forEach(input => {
			// console.log(input);
			if (input.type == 'radio') {
				if (! validRadios[input.name])	validRadios[input.name] = false;
				if (input.checked)							validRadios[input.name] = true;
			}
			else {
				// console.log(input, input.value, input.checkValidity())
				if (input.getAttribute('aria-required') == 'true' && ! input.value.trim()) valid = false;
				// if (! input.value.trim() || ! input.checkValidity()) valid = false;
			}
		});

		validRadios.forEach(validRadio => {
			if (! validRadio) valid = false;
		});

		return valid;
	}
}
