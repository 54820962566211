import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

let possibleSliders = [
	".js-slider-axis",
	".js-slider-axis-2",
	".js-slider-axis-3",
	".js-slider-offer",
	".js-slider-partners",
	".js-slider-footer",
	".js-slider-careers",
	".js-slider-3col",
	".js-slider-gallery",
	".swiper--gallery"
];
(async () => {
	if (possibleSliders.some((x) => document.querySelector(x))) {
		let Swiper = await import("swiper");
		Swiper = Swiper.default
		Swiper.use([Navigation, Pagination, EffectFade, Autoplay])

		const sliderAxis = new Swiper(".js-slider-axis .swiper-container", {
			freeMode: true,
			speed: 1000,
			navigation: {
				nextEl: '.js-slider-axis .js-swiper-nav-next',
				prevEl: '.js-slider-axis .js-swiper-nav-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 4,
					simulateTouch: false
				},
			}
		});
		// const sliderAxisNext = document.querySelector('.js-slider-axis .js-swiper-nav-next');
		// if(sliderAxisNext) sliderAxisNext.addEventListener('click', function(){
		// 	// sliderAxis.translateTo(25, 100)
		// 	// sliderAxis.setProgress(0.3);
		// 	sliderAxis.slideTo(1.4)
		// 	// console.log('sliderAxis', sliderAxis.slidesSizesGrid[0], sliderAxis.slidesSizesGrid[0] / 4);
		// })

		const sliderAxis2 = new Swiper(".js-slider-axis-2 .swiper-container", {
			autoplay: {
				delay: 3000,
			},
			freeMode: true,
			speed: 1000,
			navigation: {
				nextEl: '.js-slider-axis-2 .js-swiper-nav-next',
				prevEl: '.js-slider-axis-2 .js-swiper-nav-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 3,
					simulateTouch: false
				},
			}
		});

		const sliderAxis3 = new Swiper(".js-slider-axis-3 .swiper-container", {
			autoplay: {
				delay: 3000,
			},
			freeMode: true,
			speed: 1000,
			navigation: {
				nextEl: '.js-slider-axis-3 .js-swiper-nav-next',
				prevEl: '.js-slider-axis-3 .js-swiper-nav-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				480: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 5,
					simulateTouch: false
				},
			}
		});
		
		const sliderOffer = new Swiper(".js-slider-offer .swiper-container", {
			autoplay: {
				delay: 5000,
			},
			speed: 1000,
			spaceBetween: 20,
			navigation: {
				nextEl: '.js-slider-offer .js-swiper-nav-next',
				prevEl: '.js-slider-offer .js-swiper-nav-prev',
			},
		});
		const offerSliderNavDOM = document.querySelectorAll('.js-offer-slider-nav');
		sliderOffer.on('slideChange', () => {
			if(offerSliderNavDOM){
				offerSliderNavDOM.forEach(x => {
					x.classList.remove('is-active')
				})
				offerSliderNavDOM[sliderOffer.realIndex].classList.add('is-active')
			}
		})

		window.changeOfferSlide = (index) => {
			if(offerSliderNavDOM){
				offerSliderNavDOM.forEach(x => {
					x.classList.remove('is-active')
				})
				offerSliderNavDOM[index].classList.add('is-active')
			}
			sliderOffer.slideTo(index)
		}

		new Swiper(".js-slider-partners .swiper-container", {
			autoplay: {
				delay: 3000,
			},
			speed: 1000,
			slidesPerView: 1,
			breakpoints: {
				768: {
					slidesPerView: 3
				},
				480: {
					slidesPerView: 2
				}
			},
			navigation: {
				nextEl: '.js-slider-partners .js-swiper-nav-next',
				prevEl: '.js-slider-partners .js-swiper-nav-prev',
			},
		});

		new Swiper(".js-slider-careers .swiper-container", {
			autoplay: {
				delay: 5000,
			},
			speed: 1000,
			spaceBetween: 10,
			breakpoints: {
				992: {
					slidesPerView: 3,
				},
				660: {
					slidesPerView: 2,
				}
			},
			navigation: {
				nextEl: '.js-slider-careers .js-swiper-nav-next',
				prevEl: '.js-slider-careers .js-swiper-nav-prev',
			},
		});

		new Swiper(".js-slider-3col .swiper-container", {
			autoplay: {
				delay: 5000,
			},
			speed: 1000,
			spaceBetween: 10,
			breakpoints: {
				992: {
					slidesPerView: 3,
				},
				660: {
					slidesPerView: 2,
				}
			},
			navigation: {
				nextEl: '.js-slider-3col .js-swiper-nav-next',
				prevEl: '.js-slider-3col .js-swiper-nav-prev',
			},
		});

		new Swiper(".js-slider-footer .swiper-container", {
			loop: true,
			freeMode: true,
			autoplay: {
				delay: 1,
				disableOnInteraction: false
			},
			slidesPerView: 2,
			spaceBetween: 20,
			breakpoints: {
				992: {
					slidesPerView: 7,
				},
				768: {
					slidesPerView: 5,
				},
				480: {
					slidesPerView: 4,
				}
			},
			speed: 3000,
			grabCursor: true,
			mousewheelControl: true,
			keyboardControl: true,
		});

		const sliderGallery = new Swiper(".js-slider-gallery .swiper-container", {
			autoplay: {
				delay: 5000,
			},
			speed: 1000,
			spaceBetween: 20,
			navigation: {
				nextEl: '.js-slider-gallery .js-swiper-nav-next',
				prevEl: '.js-slider-gallery .js-swiper-nav-prev',
			},
		});

		const sliderThumbnailsGallery = new Swiper(".js-slider-gallery-thumbnails", {
			speed: 1000,
			// loop: true,
			// centeredSlides: true,
			spaceBetween: "16px",
			slidesPerView: 1,
			breakpoints: {
				480: {
					slidesPerView: 2,
					spaceBetween: "16px",
				},
				768: {
					slidesPerView: 3,
					spaceBetween: "16px",

				},
				1280: {
					slidesPerView: 4,
					spaceBetween: "16px",
				},
				1440: {
					slidesPerView: 4,
					spaceBetween: "16px",
				},
				1600: {
					slidesPerView: 6,
					spaceBetween: "16px",
				}
			}
		})

		sliderGallery.on('slideChange', function(swipe){
			sliderThumbnailsGallery.slideTo(sliderGallery.realIndex)
		})
		
		sliderThumbnailsGallery.on('slideChange', function(swipe){
			sliderGallery.slideTo(sliderThumbnailsGallery.realIndex)
		})

		window.gallerySlideTo = function(index){
			sliderGallery.slideTo(index)
		}

		new Swiper('.swiper--gallery', {
			modules: [ Navigation, Autoplay ],
			speed: 600,
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			allowTouchMove: false,
			slideVisibleClass: 'swiper-slide-visible',
			watchSlidesVisibility: true,
			navigation: {
				nextEl: '.swiper-nav--next',
				prevEl: '.swiper-nav--prev',
				disabledClass: 'is-disabled',
			},
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true,
			},
		});
	}
})();

