import fslighbox from "fslightbox";

const gallery = document.querySelectorAll('.blocks-gallery-item a');
gallery.forEach( item => {
    item.setAttribute('data-fslightbox', 'gallery');
    let title = item.parentNode.querySelector('.blocks-gallery-item__caption');

    if(title != null){
        item.setAttribute('data-alt', title.textContent);
    }
});
refreshFsLightbox();