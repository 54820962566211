// const svgMapContainer = document.querySelector('.js-map')

// if(svgMapContainer){
// 	const svgPaths = svgMapContainer.querySelectorAll('.point');
// 	const mapText = document.querySelector('.js-map-label')
// 	const mapTiles = document.querySelectorAll('.js-map-tile')

// 	function eventFire(el, etype){
// 		if (el.fireEvent) {
// 			el.fireEvent('on' + etype);
// 		} else {
// 			var evObj = document.createEvent('Events');
// 			evObj.initEvent(etype, true, false);
// 			el.dispatchEvent(evObj);
// 		}
// 	}

// 	setTimeout(() => {
// 		eventFire(document.querySelector('[data-value="Warszawa"]'), 'click')
// 	}, 1000)
	
// 	svgPaths.forEach((x, i) => {
// 		x.addEventListener('click', () => {
// 			mapTiles.forEach(y => y.classList.add('hidden'))
// 			document.querySelector(`[data-id="${x.getAttribute('data-value')}"]`).classList.remove('hidden')
// 			let cords = x.getBoundingClientRect();
// 			mapText.classList.remove('hidden')
// 			mapText.querySelector('span').textContent = x.getAttribute('data-value');
// 			mapText.style.left = cords.x + cords.width / 2 - mapText.clientWidth / 2 +"px";
// 			mapText.style.top = cords.y + window.scrollY + cords.height / 2 +"px";
// 		})
// 	})

// 	// const tiles = document.querySelectorAll('.map-tile')
// 	// window.mapLandChange = (title) => {
// 	// 	const landPath = svgMapContainer.querySelector(`path[title='${title}']`);
// 	// 	svgPaths.forEach(y => y.classList.remove('land--is-active'))
// 	// 	landPath.classList.add('land--is-active')
// 	// 	tiles.forEach(y => y.classList.remove('is-active'))
// 	// 	const mapTiles = document.querySelectorAll(`.map-tile[title='${title}']`);
// 	// 	mapTiles.forEach(y => y.classList.add('is-active'))

// 	// 	const bbox = landPath.getBBox();
// 	// 	const ctm = landPath.getScreenCTM();
// 	// 	mapText.style.top = bbox.y * ctm.a + bbox.height * ctm.a / 2 +"px";
// 	// 	mapText.style.left = bbox.x * ctm.a + bbox.width * ctm.a / 2 +"px";
// 	// 	mapText.innerHTML = title
// 	// 	mapText.classList.add('inline-block')
// 	// 	mapText.classList.remove('hidden')
// 	// }
// }

const svgMapContainer = document.querySelector('.js-map')

if(svgMapContainer){
	const svgPaths = svgMapContainer.querySelectorAll('.land');
	const mapText = document.querySelector('.js-map-text')

	function eventFire(el, etype){
		if (el.fireEvent) {
			el.fireEvent('on' + etype);
		} else {
			var evObj = document.createEvent('Events');
			evObj.initEvent(etype, true, false);
			el.dispatchEvent(evObj);
		}
	}

	setTimeout(() => {
		eventFire(document.querySelector('[data-hook="zachodnio-pomorskie"]'), 'click')
	}, 1000)

	const tiles = document.querySelectorAll('.map-tile')
	window.mapLandChange = (title) => {
		const landPath = svgMapContainer.querySelector(`path[data-hook='${title}']`);
		svgPaths.forEach(y => y.classList.remove('land--is-active'))
		landPath.classList.add('land--is-active')
		tiles.forEach(y => y.classList.add('hidden'))

		const thisTile = document.querySelector(`.map-tile[title='${title}']`);
		thisTile.classList.remove('hidden')

		let transformX = 0;
		let transformY = 0;
		let transform = landPath.getAttribute('transform')
		// transform = transform.split(/(?=\+|\-)/);
		if(transform){
			transform = transform.match(/\(([^()]*)\)/)[1];
			transform = transform.split(' ')
			transformX = transform[0]
			transformY = transform[1]
		}

		const bbox = landPath.getBBox();
		const ctm = landPath.getScreenCTM();
		mapText.style.top = (bbox.y * ctm.a + bbox.height * ctm.a / 1.8 ) + (Number(transformY) * ctm.a) +"px";
		mapText.style.left = (bbox.x * ctm.a + bbox.width * ctm.a / 2) + (Number(transformX) * ctm.a) +"px";
		mapText.innerHTML = landPath.getAttribute('title')
		mapText.classList.add('inline-block')
		mapText.classList.remove('hidden')
	}
}