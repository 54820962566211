async function fetchWrapper({ url, method, body }) {
	let formData
	if (body) {
		formData = new FormData()
		for (const key in body) {
			formData.append(key, body[key])
		}
	}

	let response = await fetch(url, {
		method,
		credentials: 'include',
		headers: {},
		body: formData || null,
	})

	const status = response.status
	const responseText = await response.text()
	try {
		response = JSON.parse(responseText)
	} catch (e) {
		console.log(e)
		if (status === 401) response = { message: 'Unauthorized' }
	}

	return [status, response]
}

export default fetchWrapper
