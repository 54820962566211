import { reveal, preReveal } from '../modules/scroll-reveal';
import ScrollAction from "../modules/scroll-action";

// import Sticky from "sticky-js";

// new Sticky(".js-sticky");

preReveal('.js-reveal');

/** Hide */
window.fade = function(el){
	// state.isFullyLoaded = true;
	// document.body.scrollTop = document.documentElement.scrollTop = 0;
	// document.querySelector(el).classList.add('is-faded')
	reveal('.js-reveal', { reset: true });
}

if(document.querySelector('.js-site-header')){
	new ScrollAction({
		wrapper: ".js-site-header",
		offset: 0,
		classToAdd: "is-sticky",
		postClassToAdd: "is-post-sticky",
		preClassToAdd: "is-pre-sticky",
		prePostClassesTimeout: 1,
	}).init();
}


