function accordionsContainer(containerSelector, accordionSelector, toggleSelector, bodySelector, debug = false) {
  class Accordion {
    constructor(toggle, body) {
      this.toggleEl = toggle;
      this.bodyEl = body;
      this.isOpen = false;
      this.naturalHeight;

      this.init();
    }
    close() {
      this.bodyEl.style.height = 0;
      this.toggleEl.classList.remove('is-open');
      this.isOpen = false;
    }
    open() {
      this.bodyEl.style.height = `${this.naturalHeight}px`;
      this.toggleEl.classList.add('is-open');
      this.isOpen = true;
    }
    toggle() {
      if (this.isOpen) {
        this.close();
      }
      else {
        this.open();
      }
    }
    init() {
      this.bodyEl.style.height = 'auto';
      this.naturalHeight = this.bodyEl.offsetHeight;
      if (! this.isOpen) {
        this.close();
      }
      else {
        this.open();
      }
    }
  }

  const changeEvent = new CustomEvent("accordionChange", { bubbles: true, cancelable: false });
  const containers = document.querySelectorAll(containerSelector);
  containers.forEach(container => {
    let accordionObjects = [];
    const accordions =  container.querySelectorAll(accordionSelector);
    accordions.forEach((accordion, index) => {
      let toggle = accordion.querySelector(toggleSelector);
      let body = accordion.querySelector(bodySelector);
      if (! toggle || ! body) {
        if (debug) console.log(`accordionsContainer: Toggle or Body missing for container ${index}...`);
        return;
      }
  
      accordionObjects.push(new Accordion(toggle, body));
    });
  
    container.addEventListener('click', function(event) {
      event.target.dispatchEvent(changeEvent);

      accordionObjects.forEach(accordionObject => {
        if (event.target === accordionObject.toggleEl || accordionObject.toggleEl.contains(event.target)) {
          accordionObject.toggle();

          accordionObjects.forEach(accordionObject => {
            if (! accordionObject.toggleEl.contains(event.target)) accordionObject.close();
          });
        }
        // else {
        //   accordionObject.close();
        // }
      });
    });
    window.addEventListener('resize', function() {
      accordionObjects.forEach(accordionObject => {
        accordionObject.init();
      });
    });
  });
}

window.addEventListener('load', () => {
  accordionsContainer('.js-acc-main', '.js-acc-item', '.js-acc-toggle', '.js-acc-body');
});
